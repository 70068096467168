import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  FANTA_COLL_ID,
  FANTA_SESSION_ID,
  FANTA_USERS_COLL_ID,
} from "../constants";
import { UserFantaInfo } from "../interfaces";
import { auth, db } from "../fbConfig";

export function useFantaInfo() {
  const [userFantaInfo, setUserFantaInfo] = useState<
    undefined | null | UserFantaInfo
  >(undefined);
  const [error, setError] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState<undefined | boolean>(undefined);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [setIsSignedIn]);

  useEffect(() => {
    if (isSignedIn) {
      const onSnapshotClear = onSnapshot(
        doc(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_USERS_COLL_ID,
          auth.currentUser?.email ?? "sfsdf"
        ),
        (snap) => {
          if (snap.exists()) {
            setUserFantaInfo(snap.data() as UserFantaInfo);
          } else {
            setUserFantaInfo(null);
          }
        },
        (err) => {
          console.error("ERROR WHEN CHECKING IF USER HAS TEAM", err);
          setError(true);
          setUserFantaInfo(null);
        }
      );
      return () => {
        onSnapshotClear();
      };
    }
  }, [isSignedIn]);
  return [userFantaInfo, error] as [undefined | null | UserFantaInfo, boolean];
}
