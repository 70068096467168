// Import FirebaseAuth and firebase.
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { auth } from "../fbConfig";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};
interface ISignInScreenProps {
  isSignedIn: undefined | boolean | null;
}
function SignInScreen({ isSignedIn }: ISignInScreenProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={3}
    >
      {isSignedIn === true ? (
        <>
          <Typography color="text.primary" mb={1}>
            {auth.currentUser?.email}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              auth.signOut();
            }}
          >
            LOG OUT
          </Button>
        </>
      ) : isSignedIn === false ? (
        <>
          {/* @ts-ignore */}
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default SignInScreen;
