import React, { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import GradBorder from "./CustomBorder";

interface ICustomDialogProps {
  children: ReactNode;
  open: boolean;
  borderColor?: string;
  onClose: () => void;
}

function CustomDialog(props: ICustomDialogProps) {
  const { children, open, onClose, borderColor } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      <GradBorder color={borderColor}>{children}</GradBorder>
    </Dialog>
  );
}

export default CustomDialog;
