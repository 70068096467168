import { Checkbox, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { MAIN_GRAD_START } from "../guiConstants";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomButton from "./CustomButton";
import { useMutation } from "react-query";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../fbConfig";
import {
  FANTA_COLL_ID,
  FANTA_PROFESSORS_COLL_ID,
  FANTA_SCHOOLS_COLL_ID,
  FANTA_USERS_COLL_ID,
} from "../constants";
import { FANTA_SESSION_ID } from "../constants";
import { UserFantaInfo } from "../interfaces";

export const SCHOOLS_OPTIONS = [
  { label: "Liceo Cassini Genova", value: "GE001" },
  { label: "Liceo D'Oria", value: "GE006" },
  { label: "Liceo Marconi Delpino", value: "GE023" },
  { label: "Liceo Mazzini", value: "GE011" },
  { label: "Istituto Calvino	", value: "GE018" },
  { label: "Istituto Cardarelli", value: "SP001" },
  { label: "Liceo Giordano Bruno", value: "SV004" },
  { label: "Liceo Calasanzio", value: "SV013" },
  { label: "Liceo luzzati", value: "GE032" },
  { label: "Liceo colombo", value: "GE016" },
  { label: "Liceo pacinotti", value: "SP005" },
  { label: "Liceo pertini", value: "GE019" },
];

function CreateTeamForm() {
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | undefined>(
    undefined
  );
  const [userProfessors, setUserProfessors] = useState<(string | undefined)[]>([
    undefined,
    undefined,
    undefined,
  ]);

  const [professorsOfSchools, setProfessors] = useState<{
    [schoolId: string]: string[];
  }>({});
  console.log(professorsOfSchools);

  useEffect(() => {
    if (selectedSchoolId)
      getDoc(
        doc(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_SCHOOLS_COLL_ID,
          selectedSchoolId ?? "",
          FANTA_PROFESSORS_COLL_ID,
          FANTA_PROFESSORS_COLL_ID
        )
      ).then((snap) => {
        setProfessors((prevProfessors) => ({
          ...prevProfessors,
          [selectedSchoolId]: snap.data()?.professors ?? [],
        }));
      });
  }, [selectedSchoolId]);

  const [name, setName] = useState<undefined | string>(undefined);

  console.log("professors", professorsOfSchools);
  const {
    isError: createTeamError,
    isSuccess,
    mutate: createTeam,
  } = useMutation(() => {
    const userTeam: UserFantaInfo = {
      totalPoints: 0,
      mainProfessor: teamCaptian!,
      professors: userProfessors as string[],
      professorPoints: userProfessors.reduce(
        (acc, name) => ({ ...acc, [name!]: 0 }),
        {} as { [profname: string]: number }
      ),
      rankPosition: undefined,
      name: name!,
      schoolId: selectedSchoolId!,
      subscribedAt: serverTimestamp() as unknown as Date,
    };
    console.log(userTeam, auth.currentUser?.email);
    return setDoc(
      doc(
        db,
        FANTA_COLL_ID,
        FANTA_SESSION_ID,
        FANTA_USERS_COLL_ID,
        auth.currentUser?.email ?? ""
      ),
      userTeam
    );
  });

  const [teamCaptian, setTeamCaptian] = useState<string | undefined>(undefined);
  return (
    <Box
      px={3}
      display="flex"
      flexDirection="column"
      gap={2}
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography align="center" variant="h5">
        Vuoi partecipare al Fanta Genvision?
      </Typography>
      <Box border="2px solid white" borderRadius={2}>
        <TextField
          value={name ?? ""}
          label="Nome e Cognome"
          onChange={(e) => setName(e.target.value)}
          sx={{
            color: "white",
            margin: 1,
            width: "calc(100% - 15px)",
          }}
          inputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "white" } }}
        />
      </Box>
      <CustomAutoComplete
        onChange={(newVal) => {
          setSelectedSchoolId(newVal);
        }}
        options={SCHOOLS_OPTIONS}
        label="Seleziona la tua scuola"
        sx={{ borderRadius: 1 }}
      />
      <Typography align="center">
        Seleziona i professori che vuoi nel tuo team. Usa la checkbox per
        indicare il capitano
      </Typography>
      {selectedSchoolId &&
        professorsOfSchools[selectedSchoolId] &&
        userProfessors.map((_, idx) => (
          <Box display="flex" width="100%" key={idx}>
            <CustomAutoComplete
              onChange={(newVal) => {
                const newUserProfessors = [...userProfessors];
                newUserProfessors[idx] = newVal;
                setUserProfessors(newUserProfessors);
              }}
              options={
                professorsOfSchools[selectedSchoolId]
                  .sort((a, b) => a.localeCompare(b))
                  .filter((prof) => !userProfessors.includes(prof))
                  ?.map((name) => ({
                    label: name,
                    value: name,
                  })) ?? []
              }
              label={`Seleziona il professore ${idx + 1}`}
              sx={{ borderRadius: 1 }}
            />
            <Checkbox
              sx={{ color: MAIN_GRAD_START, minWidth: 75 }}
              checked={
                teamCaptian !== undefined && teamCaptian === userProfessors[idx]
              }
              onClick={() => setTeamCaptian(userProfessors[idx])}
              //   disabled={!userProfessors[idx]}
            />
          </Box>
        ))}
      <CustomButton
        text="Conferma"
        onClick={createTeam}
        shouldAskConfirm
        confirmMsg="Confermi di voler creare il team?"
        disabled={
          !name ||
          !selectedSchoolId ||
          !teamCaptian ||
          userProfessors.some((prof) => !prof) ||
          userProfessors.some((prof) =>
            prof ? !professorsOfSchools[selectedSchoolId].includes(prof) : true
          )
        }
      />
      {createTeamError && (
        <Typography align="center" color="error">
          C'è stato un errore nel creare il tuo team, Riprova più tardi. Se il
          probleam persiste, contattaci su instagram.
        </Typography>
      )}
      {isSuccess && (
        <Typography align="center">
          Il tuo team è stato creato con successo, seguici su instagram per
          ricevere aggiornamenti
        </Typography>
      )}
    </Box>
  );
}

export default CreateTeamForm;
