import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TeamOverview from "./TeamOverview";
import { MAIN_GRAD_START } from "../guiConstants";
import EventsPage from "./EventsPage";
import Rank from "./Rank";
import {
  Navigate,
  Outlet,
  ReactLocation,
  Route,
  Router,
  Link,
} from "@tanstack/react-location";
import Rules from "./Rules";
import AuthGuard from "./AuthGuard";
import { useEffect, useState } from "react";
import { auth } from "../fbConfig";
import { useFantaInfo } from "../hooks/useFantaInfo";
import Console from "./Console";
import TicketsReferral from "./TicketsReferral";

const pageRoutes = [
  {
    path: "/eventi",
    element: (
      <AuthGuard>
        <EventsPage />
      </AuthGuard>
    ),
    name: "Home",
  },
  {
    path: "/classifica",
    element: (
      <AuthGuard>
        <Rank />
      </AuthGuard>
    ),
    name: "Classifica",
  },
  {
    path: "/team",
    element: (
      <AuthGuard>
        <TeamOverview />
      </AuthGuard>
    ),
    name: "Profilo",
  },
];

export const routes: Route[] = [
  ...pageRoutes,
  {
    path: "/",
    element: <Navigate to={"/eventi"} />,
  },
  {
    path: "/console",
    element: (
      <AuthGuard noTeamNeeded>
        <Console />
      </AuthGuard>
    ),
  },
  {
    path: "/referral",
    element: (
      <AuthGuard>
        <TicketsReferral />
      </AuthGuard>
    ),
  },
  {
    path: "/regolamento",
    element: <Rules />,
  },
  {
    path: "**",
    element: <Navigate to={"/team"} />,
  },
];
const location = new ReactLocation();

function Sections() {
  const [isSignedIn, setIsSignedIn] = useState<undefined | boolean>(undefined);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [setIsSignedIn]);
  const [fantaInfo] = useFantaInfo();

  return (
    <Router routes={routes} location={location}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Outlet />
        {isSignedIn &&
          !!fantaInfo &&
          [
            ...pageRoutes.map((route) => route.path),
            "/regolamento",
            "/referral",
          ].includes(window.location.pathname) && (
            <Box
              display="flex"
              justifyContent="space-between"
              height={50}
              borderTop={`1px solid ${MAIN_GRAD_START}`}
              position="fixed"
              bottom={0}
              width="100%"
              bgcolor="#000"
            >
              {pageRoutes.map(({ path, name }, idx) => (
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // sx={{ color: page === idx ? MAIN_GRAD_START : undefined }}
                  // onClick={() => setPage(idx)}
                  key={path}
                >
                  <Link
                    to={path}
                    getActiveProps={() => ({
                      style: { color: MAIN_GRAD_START },
                    })}
                  >
                    <Typography>{name}</Typography>
                  </Link>
                </Box>
              ))}
            </Box>
          )}{" "}
      </Box>
    </Router>
  );
}

export default Sections;
