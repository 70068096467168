import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CreateEventForm from "./CreateEventForm";
import CustomButton from "./CustomButton";
import { collection, onSnapshot } from "firebase/firestore";
import {
  FANTA_COLL_ID,
  FANTA_EVENTS_COLL_ID,
  FANTA_SCHOOLS_COLL_ID,
  FANTA_SESSION_ID,
} from "../constants";
import { SCHOOLS_OPTIONS } from "../App";
import { auth, db, functions } from "../fbConfig";
import CustomAutoComplete from "./CustomAutoComplete";
import EventList from "./EventList";
import { FantaEvent } from "../interfaces";
import { httpsCallable } from "firebase/functions";

function Console() {
  const [addEventOpen, setAddEventOpen] = useState(false);

  const [eventsBySchool, setEventsBySchool] = useState<{
    [schoolId: string]: FantaEvent[];
  }>({});

  useEffect(() => {
    const onSnapshotClearers = SCHOOLS_OPTIONS.map(({ value: schoolId }) =>
      onSnapshot(
        collection(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_SCHOOLS_COLL_ID,
          schoolId,
          FANTA_EVENTS_COLL_ID
        ),
        (schoolEventsSnap) => {
          setEventsBySchool((prev) => ({
            ...prev,
            [schoolId]: schoolEventsSnap.docs.map((doc) => ({
              ...doc.data(),
              date: (
                doc.data().date as firebase.default.firestore.Timestamp
              ).toDate(),
            })) as FantaEvent[],
          }));
        },
        () => {}
      )
    );

    return () => {
      onSnapshotClearers.forEach((clearOnSnapshot) => {
        clearOnSnapshot();
      });
    };
  }, []);

  const [selectedSchoolId, setSelectedSchoolId] = useState<string | undefined>(
    undefined
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      px={2}
      width="100%"
    >
      <Typography variant="h4" my={2}>
        Console
      </Typography>
      {auth.currentUser?.email === "gabriele.filaferro@gmail.com" && (
        <>
          <CustomButton
            text="Calcola rank"
            onClick={() => {
              const updateRank = httpsCallable(
                functions,
                "fanta-updateFantaRankOnCall"
              );

              updateRank()
                .then((res) => {
                  console.info(res);
                })
                .catch((err) => {
                  console.error("ERROR WHEN COMPUTING RANK", err);
                });
            }}
          />
        </>
      )}
      <CustomButton
        text={addEventOpen ? "Chiudi" : "Aggiungi evento"}
        onClick={() => setAddEventOpen(!addEventOpen)}
      />
      {addEventOpen && <CreateEventForm />}

      <CustomAutoComplete
        onChange={(newVal) => {
          setSelectedSchoolId(newVal);
        }}
        options={SCHOOLS_OPTIONS}
        label="Scuola da visualizzare"
        sx={{ borderRadius: 1 }}
      />
      {selectedSchoolId && eventsBySchool[selectedSchoolId] && (
        <>
          <Typography>
            Eventi totali: {eventsBySchool[selectedSchoolId].length}
          </Typography>
          <EventList events={eventsBySchool[selectedSchoolId]} />
        </>
      )}
    </Box>
  );
}

export default Console;
