import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { MAIN_GRAD_START } from "../guiConstants";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomButton from "./CustomButton";
import { useMutation } from "react-query";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../fbConfig";
import {
  FANTA_COLL_ID,
  FANTA_EVENTS_COLL_ID,
  FANTA_PROFESSORS_COLL_ID,
  FANTA_SCHOOLS_COLL_ID,
  FANTA_SESSION_ID,
} from "../constants";
import { SCHOOLS_OPTIONS } from "../App";
import { AVAIL_EVENTS } from "./Rules";
import { FantaEvent } from "../interfaces";

function CreateEventForm() {
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | undefined>(
    undefined
  );

  const [professor, setProfessor] = useState<undefined | string>(undefined);

  const [professorsOfSchools, setProfessors] = useState<{
    [schoolId: string]: string[];
  }>({});

  useEffect(() => {
    if (selectedSchoolId)
      getDoc(
        doc(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_SCHOOLS_COLL_ID,
          selectedSchoolId ?? "",
          FANTA_PROFESSORS_COLL_ID,
          FANTA_PROFESSORS_COLL_ID
        )
      ).then((snap) => {
        setProfessors((prevProfessors) => ({
          ...prevProfessors,
          [selectedSchoolId]: snap.data()?.professors ?? [],
        }));
      });
  }, [selectedSchoolId]);

  const [eventDescription, setEventDescription] = useState<undefined | string>(
    undefined
  );

  const selectedEvent = eventDescription
    ? AVAIL_EVENTS.filter((ev) => ev.description === eventDescription)[0]
    : undefined;

  const {
    isError: createTeamError,
    isSuccess,
    mutate: addEvent,
  } = useMutation(() => {
    const newEvent: FantaEvent = {
      date: new Date(),
      ...selectedEvent!,
      processed: false,
      professor: professor!,
    };
    console.log(newEvent, auth.currentUser?.email);
    return addDoc(
      collection(
        db,
        FANTA_COLL_ID,
        FANTA_SESSION_ID,
        FANTA_SCHOOLS_COLL_ID,
        selectedSchoolId!,
        FANTA_EVENTS_COLL_ID
      ),
      newEvent
    );
  });

  return (
    <Box
      px={3}
      display="flex"
      flexDirection="column"
      gap={2}
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography align="center" variant="h5">
        Inserisci i dati dell'evento
      </Typography>
      <CustomAutoComplete
        onChange={(newVal) => {
          setEventDescription(newVal);
        }}
        options={AVAIL_EVENTS.map(({ description }) => ({
          label: description,
          value: description,
        }))}
        label="Descrizione"
        sx={{ borderRadius: 1 }}
      />
      <Typography align="center" variant="h4" color={MAIN_GRAD_START}>
        {selectedEvent && "+"}
        {selectedEvent?.points}
      </Typography>
      <CustomAutoComplete
        onChange={(newVal) => {
          setSelectedSchoolId(newVal);
        }}
        options={SCHOOLS_OPTIONS}
        label="Seleziona la scuola"
        sx={{ borderRadius: 1 }}
      />
      {selectedSchoolId && professorsOfSchools[selectedSchoolId] && (
        <Box display="flex" width="100%">
          <CustomAutoComplete
            onChange={(newVal) => {
              setProfessor(newVal);
            }}
            options={
              professorsOfSchools[selectedSchoolId]
                .sort((a, b) => a.localeCompare(b))
                ?.map((name) => ({
                  label: name,
                  value: name,
                })) ?? []
            }
            label={`Seleziona il professore `}
            sx={{ borderRadius: 1 }}
          />
        </Box>
      )}
      <CustomButton
        text="Conferma"
        onClick={addEvent}
        shouldAskConfirm
        confirmMsg="Confermi di voler creare l'evento?"
        disabled={!eventDescription || !selectedSchoolId || !professor}
      />
      {createTeamError && (
        <Typography align="center" color="error">
          C'è stato un errore nel creare l'evento. SCRIVI A GENGIO
        </Typography>
      )}
      {isSuccess && (
        <Typography align="center">
          L'evento è stato creato con successo
        </Typography>
      )}
    </Box>
  );
}

export default CreateEventForm;
