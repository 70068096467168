import { Dialog, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Fragment, useState } from "react";
import { MAIN_GRAD_START } from "../guiConstants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { format } from "date-fns";
import { FantaEvent } from "../interfaces";

function EventList({
  events,
  userProfessors,
}: {
  events: FantaEvent[];
  userProfessors?: string[];
}) {
  const [currentEvent, setCurrentEvent] = useState<null | FantaEvent>(null);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="65vh"
        py={2}
        px={4}
        gap={1}
        sx={{ overflowY: "auto" }}
      >
        {events
          .sort((a, b) => b.date.getTime() - a.date.getTime())
          .map((event, idx) => {
            const { professor, points, processed } = event;
            const color = userProfessors?.includes(professor)
              ? MAIN_GRAD_START
              : undefined;
            return (
              <Fragment key={idx}>
                <Box
                  borderRadius={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={1}
                  sx={{ opacity: processed ? 1 : 0.5 }}
                >
                  <Typography color={color}>{professor}</Typography>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Typography color={color}>{points}</Typography>
                    <IconButton
                      onClick={() => setCurrentEvent(event)}
                      sx={{ ml: 1 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Box>
                </Box>
                {idx !== events.length - 1 && (
                  <Box
                    width="85%"
                    mx="auto"
                    height={"1px"}
                    bgcolor={MAIN_GRAD_START}
                    color={"#000"}
                  >
                    {"c"}
                  </Box>
                )}
              </Fragment>
            );
          })}
      </Box>
      <Dialog
        open={!!currentEvent}
        onClose={() => setCurrentEvent(null)}
        sx={{ bgcolor: "transparent" }}
      >
        <Box
          bgcolor="#000"
          color="white"
          p={3}
          border={`2px solid ${MAIN_GRAD_START}`}
          borderRadius={2}
        >
          <Typography align="center">
            {currentEvent?.description ?? ""}
            <br />
            <br />
          </Typography>
          <Typography align="center">
            {currentEvent?.date ? format(currentEvent?.date, "dd/MM/yyyy") : ""}
          </Typography>
        </Box>
      </Dialog>
    </>
  );
}

export default EventList;
