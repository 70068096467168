import { CSSProperties, useState } from "react";
import { MAIN_GRAD } from "../guiConstants";
import ConfirmDialog from "./ConfirmDialog";

interface IMyButtonProps {
  onClick: () => void;
  text: string;
  style?: CSSProperties;
  disabled?: boolean;
  shouldAskConfirm?: boolean;
  confirmMsg?: string;
}

function CustomButton(props: IMyButtonProps) {
  const { onClick, text, disabled, style, shouldAskConfirm, confirmMsg } =
    props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  return (
    <>
      <ConfirmDialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        onConfirm={() => {
          setConfirmOpen(false);
          onClick();
        }}
      >
        {confirmMsg ?? "Confermi?"}
      </ConfirmDialog>
      <button
        style={{
          background: MAIN_GRAD,
          color: "#000",
          padding: 12,
          borderWidth: 0,
          fontSize: "1.2rem",
          borderRadius: 5,
          fontWeight: 700,
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.5 : 1,
          cursor: "pointer",
          ...style,
        }}
        onClick={
          disabled
            ? () => {}
            : shouldAskConfirm
            ? () => setConfirmOpen(true)
            : onClick
        }
      >
        <div>{text}</div>
      </button>
    </>
  );
}

export default CustomButton;
