import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import EventList from "./EventList";
import { FantaEvent } from "../interfaces";
import { SCHOOLS_OPTIONS } from "./CreateTeamForm";
import { useFantaInfo } from "../hooks/useFantaInfo";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../fbConfig";
import {
  FANTA_COLL_ID,
  FANTA_EVENTS_COLL_ID,
  FANTA_SCHOOLS_COLL_ID,
} from "../constants";
import { FANTA_SESSION_ID } from "../constants";

const EVENTS_OPEN = true;

function EventsPage() {
  const [fantaInfo] = useFantaInfo();

  const { schoolId, professors } = fantaInfo ?? {};

  const schoolName =
    SCHOOLS_OPTIONS.find((school) => school.value === schoolId)?.label ?? "";

  const [events, setEvents] = useState<FantaEvent[]>([]);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (schoolId) {
      getDocs(
        query(
          collection(
            db,
            FANTA_COLL_ID,
            FANTA_SESSION_ID,
            FANTA_SCHOOLS_COLL_ID,
            schoolId,
            FANTA_EVENTS_COLL_ID
          ),
          where("processed", "==", true)
        )
      )
        .then((eventsSnap) => {
          setEvents(
            eventsSnap.docs.map((doc) => ({
              ...doc.data(),
              date: (doc.data().date as Timestamp).toDate(),
            })) as FantaEvent[]
          );
        })
        .catch((err) => {
          setError(true);
          console.error("ERROR WHEN GETTING EVENTS", err);
        });
    }
  }, [schoolId]);
  return (
    <Box>
      <Typography variant="h5" textTransform="uppercase" align="center" mt={2}>
        <b>
          Eventi <br /> {schoolName}
        </b>
      </Typography>
      {EVENTS_OPEN ? (
        <>
          {error && (
            <Typography align="center" color="error" px={6} mt={2}>
              C'è stato un errore, riprova più tardi
            </Typography>
          )}
          <EventList events={events} userProfessors={professors} />
        </>
      ) : (
        <Typography align="center" px={6} mt={2}>
          <i>Questa sezione sarà disponibile alla chiusura delle iscrizioni</i>
        </Typography>
      )}
    </Box>
  );
}

export default EventsPage;
