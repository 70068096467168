import { Box, Typography } from "@mui/material";
import { useNavigate } from "@tanstack/react-location";
import { useFantaInfo } from "../hooks/useFantaInfo";
import { KAPLAN_URL } from "../constants";
import CustomButton from "./CustomButton";

export const OTHER_EVENTS = [
  { description: `Il tuo biglietto per il GV vale`, points: 50 },
  {
    description: `I biglietti degli amici che convinci a venire a Genvision valgono`,
    points: 20,
  },
];

export const KAPLAN_EVENTS = [
  {
    description: `Viene a scuola vestito/a di blu ed entra in classe dicendo "hello!"`,
    points: 20,
  },
  {
    description: `Ripete una frase in 3 o più lingue`,
    points: 40,
  },
  {
    description: `Fa il saluto della regina`,
    points: 50,
  },
  {
    description: `Dice "The cat is on the table"`,
    points: 30,
  },
  {
    description: `Beve il the delle macchinette`,
    points: 20,
  },
];

export const AVAIL_EVENTS = [
  { description: `Si veste con un capo viola o lilla`, points: 40 },
  { description: `Stringe la mano al talento`, points: 20 },
  { description: `Dice "Gengio"`, points: 20 },
  { description: `Scrive "Genvision" alla lavagna`, points: 10 },
  { description: `Suona uno strumento nell'intervallo`, points: 30 },
  {
    description: `Fa canestro con una pallina di carta nel cestino`,
    points: 40,
  },
  {
    description: `Scrive sul gruppo Whatsapp della classe/scuola "FORZA TALENTO"`,
    points: 10,
  },
  {
    description: `A fine lezione dice a tutta la classe di andare al Genvision`,
    points: 30,
  },
  {
    description: `Durante l'intervallo compra le croccantelle alle macchinette`,
    points: 10,
  },
  {
    description: `Mette una storia sul suo profilo IG con scritto "FORZA SCUOLA" e tagga @genvision`,
    points: 30,
  },
  { description: `Offre un caffè al/alla preside`, points: 50 },
  { description: `Entra in classe gridando "auguri Gengio"`, points: 30 },
  { description: `Lancia un aeroplanino nel corridoio`, points: 10 },
  { description: `Porta una torta in classe`, points: 60 },
  {
    description: `Spiega in classe con gli occhiali da sole (per 15 sec)`,
    points: 20,
  },
  { description: `Entra in classe camminando all'indietro`, points: 30 },
  {
    description: `Manda una foto del suo animale domestico in chat al rappresentante`,
    points: 20,
  },
  {
    description: `Canta insieme a un altro prof una canzone a scelta di Sanremo 2022`,
    points: 60,
  },
  {
    description: `Si nasconde e fa "buh" alla prima persona che passa`,
    points: 80,
  },
  {
    description: `Scrive "forza talento" nella lezione sul registro elettronico`,
    points: 70,
  },
  { description: `Indossa un cappello`, points: 10 },
  { description: `Indossa i tacchi`, points: 10 },
  {
    description: `Fa una partita a carte con un collaboratore scolastico durante l'intervallo`,
    points: 40,
  },
  { description: `Scarica TikTok sul suo telefono`, points: 20 },
  { description: `Segue @genvision su Insta`, points: 10 },
  { description: `Compra biglietto per il GV`, points: 70 },
  {
    description: `Registra un video in cui dice di andare al GV e lo fa pubblicare nelle storie della pagina dell'istituto`,
    points: 100,
  },
  { description: `Fa dissing a un'altra scuola`, points: 100 },
  {
    description: `Ringrazia GV Network per la grande opportunità`,
    points: 50,
  },
  { description: `Fa lezione sulle scale`, points: 10 },
];

function Rules() {
  const navigate = useNavigate();
  const [fantaInfo] = useFantaInfo();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={2}
      mb={2}
    >
      <Typography width="100%" align="center" variant="h4" mb={3}>
        <b>Regolamento</b>
      </Typography>
      <Box px={3} sx={{ overflowY: "auto" }}>
        Ogni giocatore si iscrive su <b>fanta.genvision.it</b> e sceglie{" "}
        <b>tre professori</b>, della sua scuola, da schierare nella{" "}
        <b>sua squadra</b>, che gli consentiranno di fare punti.
        <br />
        <br /> Più i professori schierati nella sua squadra faranno punti, più
        il giocatore ne accumula.
        <br />
        <br /> Ogni giocatore può scegliere <b>
          un capitano tra i professori
        </b>{" "}
        che ha schierato, in caso in cui sia il capitano a fare{" "}
        <b>i punti, valgono doppio</b>.
        <br />
        <br /> <b>Non</b> si possono <b>cambiare i professori</b> dopo averli{" "}
        <b>schierati</b>.
        <br />
        <br /> Per <b>convalidare i punti inviare</b> foto, video o screen su
        instagram a <b>@genvision_</b> oppure via mail a{" "}
        <b>marketing@gvnetwork.it</b> , se i punti sono validi è una decisione
        insindacabile dello staff GV.
        <br />
        <br /> Non importa chi manda foto, video o screen del professore,
        <b>
          beneficiano dell’azione tutti i giocatori che hanno il professore in
          squadra
        </b>
        .
        <br />
        <br />{" "}
        <b>
          Se il professore viene fotografato o filmato senza permesso, il
          giocatore verrà immediatamente squalificato e segnalato alle autorità
          scolastiche
        </b>
        .
        <br />
        <br /> Ogni scuola è una lega(classifica),{" "}
        <b>il vincitore di ogni lega salirà sul palco</b> (in caso di pareggio
        salirà chi ha venduto più biglietti, grazie alla regola referral).
        <br />
        <br /> <b>Regola referral</b>: Per fare punti, oltre al sistema dei
        professori, è possibile fare da “referral” biglietti ovvero{" "}
        <b>convincere qualcuno ad acquistarlo</b>. Se una persona compra il
        biglietto sotto suggerimento di un giocatore, il giocatore deve farsi
        dare il codice del biglietto ed inserirlo nel campo referral su{" "}
        <b>fanta.genvision.it </b>(vale 20 punti!)
        <br />
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          py={3}
        >
          {OTHER_EVENTS.map(({ description, points }, idx) => (
            <Box
              border="1px solid #FFF"
              display="flex"
              alignItems="center"
              width="80%"
              justifyContent="space-between"
              px={2}
              py={1}
              key={idx}
            >
              <Typography>{description}</Typography>
              <Box minWidth={40} textAlign="end">
                {points}
              </Box>
            </Box>
          ))}
        </Box>
        Di seguito la lista delle{" "}
        <b>azioni che permettono di accumulare punti</b>, i punti si chiamano
        GENGI
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
        py={3}
        pb={2}
      >
        {AVAIL_EVENTS.map(({ description, points }, idx) => (
          <Box
            border="1px solid #FFF"
            display="flex"
            alignItems="center"
            width="80%"
            justifyContent="space-between"
            px={2}
            py={1}
            key={idx}
          >
            <Typography>{description}</Typography>
            <Box minWidth={40} textAlign="end">
              {points}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
        py={3}
        pb={10}
      >
        <Typography textTransform="uppercase" mb={1}>
          Powered by
        </Typography>
        <a href={KAPLAN_URL} rel="noopener noreferrer">
          <Box p={2} mb={3}>
            <img
              style={{ maxWidth: "30vw" }}
              src="/kaplanLogo.webp"
              alt="Kaplan Logo"
            />
          </Box>
        </a>
        {KAPLAN_EVENTS.map(({ description, points }, idx) => (
          <Box
            border="1px solid #FFF"
            display="flex"
            alignItems="center"
            width="80%"
            justifyContent="space-between"
            px={2}
            py={1}
            key={idx}
          >
            <Typography>{description}</Typography>
            <Box minWidth={40} textAlign="end">
              {points}
            </Box>
          </Box>
        ))}
      </Box>

      {fantaInfo === null && (
        <Box
          position="fixed"
          width="100%"
          bottom={0}
          display="flex"
          justifyContent="center"
          boxShadow={7}
          bgcolor="#000"
          py={3}
        >
          <CustomButton
            text="Crea il tuo team"
            onClick={() => navigate({ to: "/" })}
          />
        </Box>
      )}
    </Box>
  );
}

export default Rules;
