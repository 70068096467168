import { Box, Typography } from "@mui/material";
import React from "react";
import { KAPLAN_URL } from "../constants";

type Props = {};

function ClosedPage({}: Props) {
  return (
    <Box
      height={"100%"}
      width="100%"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src="/fantagv.png" style={{ height: 50 }} alt="Fanta Genvision" />
      {/* <Typography my={3} variant="h5" textTransform="uppercase">
        tornerà presto
      </Typography> */}
      <Typography textTransform="uppercase" mb={5} mt={1}>
        Tornerà presto
      </Typography>
      <a href={KAPLAN_URL} rel="noopener noreferrer">
        <Box mt={1}>
          <img
            style={{ maxWidth: "30vw" }}
            src="/kaplanLogo.webp"
            alt="Kaplan Logo"
          />
        </Box>
      </a>
    </Box>
  );
}

export default ClosedPage;
