import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MAIN_GRAD_START } from "../guiConstants";
import { useFantaInfo } from "../hooks/useFantaInfo";
import StarIcon from "@mui/icons-material/Star";
import { SCHOOLS_OPTIONS } from "./CreateTeamForm";
import { FANTA_COLL_ID, FANTA_SCHOOLS_COLL_ID } from "../constants";
import { FANTA_SESSION_ID } from "../constants";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../fbConfig";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
const ProfessorPlaceholder = ({
  points,
  name,
  highlighted,
}: {
  points: number;
  name: string;
  highlighted?: boolean;
}) => {
  const color = highlighted ? MAIN_GRAD_START : "white";
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      width="100%"
      height="100%"
      maxWidth={200}
      border={`2px solid ${color}`}
      borderRadius={1}
    >
      <Box display="flex" gap={1} alignItems="center">
        <Typography color={color}>{name}</Typography>
        {highlighted && (
          <StarIcon fontSize="small" sx={{ color: MAIN_GRAD_START }} />
        )}
      </Box>

      <Typography color={color}>{points}</Typography>
    </Box>
  );
};

function TeamOverview() {
  const [fantaInfo, error] = useFantaInfo();

  const {
    name,
    mainProfessor,
    professors,
    professorPoints,
    schoolId,
    totalPoints,
    rankPosition,
  } = fantaInfo ?? {};
  const schoolName =
    SCHOOLS_OPTIONS.find((school) => school.value === schoolId)?.label ?? "";
  const [schoolTotalUsers, setSchoolTotalUsers] = useState<undefined | number>(
    undefined
  );

  useEffect(() => {
    if (schoolId) {
      getDoc(
        doc(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_SCHOOLS_COLL_ID,
          schoolId
        )
      ).then((schoolSnap) => {
        setSchoolTotalUsers(schoolSnap.data()?.totalUsers as number);
      });
    }
  }, [schoolId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="75vh"
      justifyContent="center"
    >
      <Typography
        variant="h5"
        align="center"
        mb={4}
        textTransform="uppercase"
        mt={2}
      >
        <b> {name}</b>
        <br /> {schoolName}
      </Typography>
      {error && (
        <Typography color="error">
          C'è stato un errroe, riprova più tardi
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        px={2}
        alignItems="center"
      >
        <>
          {professors
            ?.filter((name) => name === mainProfessor)
            .map((name) => (
              <ProfessorPlaceholder
                name={name}
                points={professorPoints?.[name] ?? 0}
                highlighted
                key={name}
              />
            ))}
        </>
        <>
          {professors
            ?.filter((name) => name !== mainProfessor)
            .map((name) => (
              <ProfessorPlaceholder
                name={name}
                points={professorPoints?.[name] ?? 0}
                key={name}
              />
            ))}
        </>
        <Box mt={2} display="flex" flexDirection="column">
          <Typography variant="h5" align="center">
            {totalPoints} Gengi
          </Typography>
          {(schoolTotalUsers ?? 0) >= 15 && (
            <Typography variant="h5" align="center">
              Posizione: {rankPosition}/{schoolTotalUsers}
            </Typography>
          )}{" "}
        </Box>
        <IconButton
          onClick={() => {
            auth.signOut();
          }}
          sx={{ color: "white", mt: 4 }}
        >
          <LogoutIcon fontSize="large" sx={{ mr: 1 }} /> Esci
        </IconButton>
      </Box>
    </Box>
  );
}

export default TeamOverview;
