import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactNode, useEffect, useState } from "react";
import SignInScreen from "./SignInScreen";
import { auth } from "../fbConfig";
import { KAPLAN_URL } from "../constants";
import { CircularProgress } from "@mui/material";
import CreateTeamForm from "./CreateTeamForm";
import { sendEmailVerification, User } from "firebase/auth";
import CustomDialog from "./CustomDialog";
import CustomButton from "./CustomButton";

import { useFantaInfo } from "../hooks/useFantaInfo";
import { useNavigate } from "@tanstack/react-location";

function AuthGuard({
  children,
  noTeamNeeded,
}: {
  children: ReactNode;
  noTeamNeeded?: boolean;
}) {
  const [user, setUser] = useState<null | User | undefined>(undefined);
  const [isSignedIn, setIsSignedIn] = useState<undefined | boolean>(undefined);

  const needsEmailVerification =
    user !== null && user !== undefined && !user?.emailVerified;

  const [showEmailConfirmPopup, setShowEmailConfirmPopup] = useState(false);

  useEffect(() => {
    if (user !== null && user !== undefined && !user?.emailVerified) {
      setShowEmailConfirmPopup(true);
    }
  }, [user]);

  useEffect(() => {
    let intervalId: number | undefined = undefined;
    if (needsEmailVerification && showEmailConfirmPopup) {
      //  @ts-ignore
      intervalId = setInterval(() => {
        auth.currentUser?.getIdTokenResult(true).then(() => {
          auth.currentUser
            ?.reload()
            .then(() => {
              console.log("pazum", auth.currentUser);
              if (auth.currentUser?.emailVerified === true) {
                setShowEmailConfirmPopup(false);
              } else {
                setShowEmailConfirmPopup(true);
              }
            })
            .catch((err) => {});
        });
      }, 3000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [needsEmailVerification, showEmailConfirmPopup]);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      if (user) setUser(user);
      else setUser(null);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [setIsSignedIn]);

  const [userFantaInfo, error] = useFantaInfo();

  const navigate = useNavigate();

  return (
    <>
      {isSignedIn ? (
        <>
          {noTeamNeeded || userFantaInfo ? (
            <>{children}</>
          ) : userFantaInfo === null ? (
            <CreateTeamForm />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              {error ? (
                <Typography color="error">
                  C'è stato un errore riprova più tardi
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          {isSignedIn === false && (
            <Typography align="center" mt={2}>
              Accedi o registrati per partecipare.
            </Typography>
          )}
          <SignInScreen isSignedIn={isSignedIn} />
          {isSignedIn === false && (
            <>
              <Typography align="center" mb={3} px={3}>
                Scegli 3 professori della tua scuola, convincili a fare cose
                pazze e accumula punti.
                <br />
                <br /> I 12 giocatori col punteggio più alto (1 per ogni scuola
                partecipante) saliranno sul palco di GENVISIONXX22 per ricevere
                la gloria eterna.
                <br />
                <br /> Punteggi e regolamento completo ⬇️
              </Typography>
              <CustomButton
                text="Regolamento"
                onClick={() => navigate({ to: "/regolamento" })}
              />
              <Typography textTransform="uppercase" mt={9}>
                Powered by
              </Typography>
              <a href={KAPLAN_URL} rel="noopener noreferrer">
                <Box borderRadius={1} p={2} mb={3}>
                  <img
                    style={{ maxWidth: "30vw" }}
                    src="/kaplanLogo.webp"
                    alt="Kaplan Logo"
                  />
                </Box>
              </a>
            </>
          )}
        </Box>
      )}
      <CustomDialog
        open={showEmailConfirmPopup}
        onClose={() => setShowEmailConfirmPopup(false)}
      >
        <div
          style={{
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <p
            style={{
              fontSize: "1rem",
              textAlign: "center",
              color: "#ffca08",
            }}
          >
            Ci sembra tu non abbia verificato la mail.
            <br />
            <br />
            Se lo hai giò fatto, attendi qualche istante. altrimenti controlla
            la tua casella di posta e clicca sul link che ti abbiamo inviato.
            <br />
            <br />
            Potrebbe essere nella cartella spam.
          </p>
          <CircularProgress sx={{ color: "white" }} />
          {auth.currentUser && (
            <CustomButton
              onClick={() => sendEmailVerification(auth.currentUser!)}
              text="Invia nuova email di conferma"
            />
          )}
          {auth.currentUser && (
            <CustomButton
              onClick={() => {
                auth.signOut();

                setShowEmailConfirmPopup(false);
              }}
              text="Cambia account"
            />
          )}
        </div>
      </CustomDialog>
    </>
  );
}

export default AuthGuard;
