import { ReactNode, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import GradBorder from "./CustomBorder";
import CustomDialog from "./CustomDialog";

interface IConfirmDialogBaseProps {
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
  children: ReactNode;
}

interface IConfirmDialognoFutherProps extends IConfirmDialogBaseProps {
  shouldAskFurtherConfirm?: never;
  furtherConfirmContent?: never;
}

interface IConfirmDialogFutherProps extends IConfirmDialogBaseProps {
  shouldAskFurtherConfirm: boolean;
  furtherConfirmContent: ReactNode;
}

type IConfirmDialogProps =
  | IConfirmDialognoFutherProps
  | IConfirmDialogFutherProps;

function ConfirmDialog(props: IConfirmDialogProps) {
  const {
    open,
    onConfirm,
    children,
    onCancel,
    onClose,
    shouldAskFurtherConfirm,
    furtherConfirmContent,
  } = props;

  const [furtherConfirmOpen, setFurtherConfirmOpen] = useState(false);
  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            padding: 32,
            fontSize: "1rem",
            color: "white",
            textAlign: "center",
          }}
        >
          <div style={{ padding: 5 }}>{children}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <GradBorder style={{ margin: " 0 20px 0 0" }}>
              <IconButton
                style={{
                  color: "white",
                }}
                onClick={() => {
                  onCancel?.();
                  onClose();
                }}
              >
                <ClearIcon fontSize="large" />
              </IconButton>
            </GradBorder>
            <GradBorder style={{ margin: "0 0 0 20px" }}>
              <IconButton
                style={{
                  color: "white",
                }}
                onClick={() => {
                  if (shouldAskFurtherConfirm) setFurtherConfirmOpen(true);
                  else onConfirm();
                }}
              >
                <CheckIcon fontSize="large" />
              </IconButton>
            </GradBorder>
          </div>
        </div>
      </CustomDialog>
      {furtherConfirmContent && (
        <ConfirmDialog
          open={furtherConfirmOpen}
          onClose={() => {
            setFurtherConfirmOpen(false);
          }}
          onConfirm={() => {
            onConfirm();
            setFurtherConfirmOpen(false);
          }}
          onCancel={onClose}
        >
          {furtherConfirmContent}
        </ConfirmDialog>
      )}
    </>
  );
}

export default ConfirmDialog;
