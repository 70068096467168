/* eslint-disable no-use-before-define */
import { SxProps, Theme } from "@mui/material/styles";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { MenuItem } from "@mui/material";

// const useStyles = makeStyles((theme: Theme) => ({
//   paper: {
//     boxShadow: "none",
//     margin: 0,
//     color: "#FFFFFF",
//     backgroundColor: "#111111",
//   },
//   option: {
//     minHeight: "auto",
//     alignItems: "flex-start",
//     padding: 8,
//     paddingLeft: 20,
//     '&[aria-selected="true"]': {
//       backgroundColor: "transparent",
//     },
//     '&[data-focus="true"]': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }));

export default function CustomAutoComplete<TValue>(props: {
  disabled?: boolean;
  required?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
  options: { label: string; value: TValue }[];
  showOptionsAfter?: number;
  onChange: (newValue: TValue) => void;
}) {
  const { disabled, sx, required, label, options, onChange, showOptionsAfter } =
    props;

  const [inputValue, setInputValue] = useState<string | undefined>("");

  const showOptions =
    inputValue && inputValue?.length >= (showOptionsAfter ?? 0);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(_, newValue) => {
        onChange(newValue!.value);
      }}
      sx={{
        width: "100%",
        border: "2px solid white",
        ...sx,
      }}
      inputValue={inputValue}
      onInputChange={(ev, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText={
        <div style={{ color: "#FFF" }}>
          <i>Inizia a digitare il nome</i>
        </div>
      }
      filterOptions={showOptions ? createFilterOptions() : () => []}
      renderOption={(props, option) => (
        <MenuItem {...props}>{showOptions ? option.label : "chsudkj"}</MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            style: { color: "white", borderColor: "red" },
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            style: { color: "white", borderColor: "red" },
          }}
          variant="outlined"
          label={label}
          disabled={disabled}
          required={required}
          sx={{
            color: "white",
            margin: 1,
            opacity: disabled ? 0.65 : 1,
            width: "calc(100% - 15px)",
            borderColor: "pink",
          }}
        />
      )}
    />
  );
}
