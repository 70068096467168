import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { SCHOOLS_OPTIONS } from "../App";
import {
  FANTA_COLL_ID,
  FANTA_RANK_COLL_ID,
  FANTA_SCHOOLS_COLL_ID,
  FANTA_SESSION_ID,
} from "../constants";
import { db } from "../fbConfig";
import { MAIN_GRAD_START } from "../guiConstants";
import { useFantaInfo } from "../hooks/useFantaInfo";

const TOP_RANK_AMOUNT = 10;

const RANK_OPEN = true;

interface RankElm {
  email: string;
  name: string;
  points: number;
  position: number;
}

function Rank() {
  const [userFantaInfo] = useFantaInfo();
  const { rankPosition, name, totalPoints, schoolId } = userFantaInfo ?? {};
  const [rank, setRank] = useState<RankElm[]>([]);

  useEffect(() => {
    if (schoolId) {
      getDoc(
        doc(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_SCHOOLS_COLL_ID,
          schoolId,
          FANTA_RANK_COLL_ID,
          FANTA_RANK_COLL_ID
        )
      )
        .then((rankSnap) => {
          const newRank = (
            rankSnap.data() as {
              rank: RankElm[];
            }
          ).rank;
          setRank(newRank);
        })
        .catch((err) => {
          console.error("ERROR WHEN GETTING RANK", err);
        });
    }
  });
  const schoolName =
    SCHOOLS_OPTIONS.find((school) => school.value === schoolId)?.label ?? "";

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
      <Typography
        width="100%"
        variant="h5"
        mt={4}
        mb={7}
        align="center"
        textTransform={"uppercase"}
      >
        <b>
          TOP
          <br />
          {schoolName}
        </b>
      </Typography>
      {!RANK_OPEN ? (
        <Typography align="center" px={6}>
          <i>Questa sezione sarà disponibile alla chiusura delle iscrizioni</i>
        </Typography>
      ) : (
        <>
          {rank
            .filter((_, idx) => idx < TOP_RANK_AMOUNT)
            .map(({ name, position, points }, idx) => {
              const isCurrentUser = idx + 1 === rankPosition;
              return (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  maxWidth={260}
                  key={idx}
                >
                  <Typography
                    width="100%"
                    variant="h6"
                    color={isCurrentUser ? MAIN_GRAD_START : undefined}
                    fontWeight={isCurrentUser ? 900 : undefined}
                    my={isCurrentUser ? 1 : undefined}
                  >
                    {position}
                    {")"} {name} <b>{points}</b>
                  </Typography>
                </Box>
              );
            })}
          {rankPosition! > TOP_RANK_AMOUNT && (
            <>
              <Box
                width="70vw"
                bgcolor="white"
                height={"1px"}
                color="transparent"
                mt={2}
              >
                c
              </Box>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                maxWidth={260}
              >
                <Typography
                  width="100%"
                  variant="h6"
                  color={MAIN_GRAD_START}
                  mt={1}
                >
                  {rankPosition}
                  {")"} {name} {totalPoints}
                </Typography>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default Rank;
