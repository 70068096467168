import ReactDOM from "react-dom";
import "./index.css";
import "./customFonts.css";
import App from "./App";
import { StrictMode } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import createTheme from "@mui/material/styles/createTheme";
import { QueryClient, QueryClientProvider } from "react-query";
import ClosedPage from "./components/ClosedPage";

const theme = createTheme();

const queryClient = new QueryClient();

const isFantaOpen = false;

ReactDOM.render(
  <StrictMode>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {isFantaOpen ? <App /> : <ClosedPage />}
      </ThemeProvider>
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById("root")
);
