import { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "./CustomButton";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../fbConfig";
import {
  FANTA_COLL_ID,
  FANTA_REFERRALS_COLL_ID,
  FANTA_SESSION_ID,
  FANTA_TICKET_CODES_COLL_ID,
} from "../constants";
import { useMutation } from "react-query";

function TicketsReferral() {
  const [ownTicketCode, setOwnTicketCode] = useState<string | undefined>(
    undefined
  );
  const [isAddingReferral, setIsAddingReferral] = useState(false);
  const [newRefferalCode, setNewRefferalCode] = useState<string | undefined>(
    undefined
  );

  const [referralCodes, setReferralCodes] = useState<string[]>([]);

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      doc(
        db,
        FANTA_COLL_ID,
        FANTA_SESSION_ID,
        FANTA_TICKET_CODES_COLL_ID,
        auth.currentUser?.email ?? ""
      ),
      (ticketCodeSnap) => {
        setOwnTicketCode(ticketCodeSnap.data()?.ticketCode);
      },
      (err) => {
        console.error("ERROR WHEN GETTING OWN TICKET CODE", err);
      }
    );
    return () => {
      clearOnSnapshot();
    };
  }, []);

  const { mutate: updateTicketCode, error: updateTicketError } = useMutation(
    () => {
      console.log({
        ticketCode: ownTicketCode,
      });
      return setDoc(
        doc(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_TICKET_CODES_COLL_ID,
          auth.currentUser?.email ?? ""
        ),
        {
          ticketCode: ownTicketCode,
        }
      );
    }
  );

  const { mutate: addReferral, error: addReferralError } = useMutation(() =>
    setDoc(
      doc(
        db,
        FANTA_COLL_ID,
        FANTA_SESSION_ID,
        FANTA_REFERRALS_COLL_ID,
        newRefferalCode ?? ""
      ),
      {
        userEmail: auth.currentUser?.email,
      }
    )
  );

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      query(
        collection(
          db,
          FANTA_COLL_ID,
          FANTA_SESSION_ID,
          FANTA_REFERRALS_COLL_ID
        ),
        where("userEmail", "==", auth.currentUser?.email ?? "")
      ),
      (referralSnaps) => {
        setReferralCodes(referralSnaps.docs.map((doc) => doc.id));
      },
      (err) => {
        console.error("ERROR WHEN GETTING REFERRAL CODES", err);
      }
    );
    return () => {
      clearOnSnapshot();
    };
  }, []);

  return (
    <>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="center"
      >
        <Typography align="center" color="white" variant="h4">
          <b>BIGLIETTI</b>
        </Typography>
        <Typography align="center" color="white">
          Inserisci il codice del tuo biglietto per ricevere 50 punti
        </Typography>
        <Box border="2px solid white" borderRadius={2} width="90vw">
          <TextField
            value={ownTicketCode ?? ""}
            label="Codice del tuo biglietto"
            onChange={(e) => setOwnTicketCode(e.target.value)}
            sx={{
              color: "white",
              margin: 1,
              width: "calc(100% - 15px)",
            }}
            error={ownTicketCode ? ownTicketCode.length !== 15 : false}
            helperText={
              ownTicketCode && ownTicketCode.length !== 15
                ? "Il codice deve avere 15 caratteri alfanumerici"
                : undefined
            }
            inputProps={{
              style: { color: "white", textTransform: "uppercase" },
            }}
            InputLabelProps={{ style: { color: "white" } }}
          />
        </Box>
        <CustomButton
          text={"Aggiorna codice"}
          onClick={() => updateTicketCode()}
          disabled={ownTicketCode?.length !== 15}
        />
        {updateTicketError && (
          <Typography color="error" align="center">
            C'è stato un errore, riprova più tardi
          </Typography>
        )}
        <Box
          width="80vw"
          bgcolor="white"
          height={"1px"}
          color="transparent"
          my={3}
        >
          c
        </Box>
        {referralCodes.length > 0 && (
          <Typography variant="h6">I codici dei tuoi amici:</Typography>
        )}
        {referralCodes.map((code, idx) => (
          <Typography textTransform="uppercase" key={code}>
            {idx + 1}
            {") "}
            {code}
          </Typography>
        ))}
        {isAddingReferral && (
          <Box border="2px solid white" borderRadius={2} width="90vw">
            <TextField
              value={newRefferalCode ?? ""}
              label="Codice amico"
              onChange={(e) => setNewRefferalCode(e.target.value)}
              sx={{
                color: "white",
                margin: 1,
                width: "calc(100% - 15px)",
              }}
              inputProps={{
                style: { color: "white", textTransform: "uppercase" },
              }}
              InputLabelProps={{ style: { color: "white" } }}
              error={newRefferalCode ? newRefferalCode.length !== 15 : false}
              helperText={
                newRefferalCode && newRefferalCode.length !== 15
                  ? "Il codice deve avere 15 caratteri alfanumerici"
                  : undefined
              }
            />
          </Box>
        )}
        {addReferralError && (
          <Typography color="error" align="center">
            C'è stato un errore, riprova più tardi
          </Typography>
        )}
        <CustomButton
          text={
            isAddingReferral ? "Conferma codice amico" : "Aggiungi codice amico"
          }
          onClick={
            isAddingReferral
              ? () => addReferral()
              : () => setIsAddingReferral(true)
          }
          disabled={
            isAddingReferral &&
            !newRefferalCode &&
            newRefferalCode?.length !== 15
          }
        />
        <Typography align="center" color="white" variant="subtitle2" mt={2}>
          <i>
            I Gengi relativi ai biglietti verranno aggiunti alla fine del
            FantaGV
          </i>
        </Typography>
      </Box>
    </>
  );
}

export default TicketsReferral;
